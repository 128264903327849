<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
       
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
     <user-view-password-change :user-data="userData" />  
        </b-col>
      </b-row>

      <b-row>
      
        <b-col
          cols="12"
          lg="6"
        >
        <suggestion-definition  :user-data="userData" ></suggestion-definition>
    
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
        <suggestion-solution  :user-data="userData" ></suggestion-solution>
    
        </b-col>
      </b-row>

    
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import userStoreModule from '../participantStoreModule'
 
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewPasswordChange from './UserViewPasswordChange.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import SuggestionDefinition from './SuggestionDefinition.vue'
import SuggestionSolution from './SuggestionSolution.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    SuggestionDefinition,
    SuggestionSolution,
    // Local Components
    
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    UserViewPasswordChange
 
  },
  setup() {
    const userData = ref(null)
    const userDetailData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'apps-suggestions'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('apps-suggestions/fetchSuggestion', { id: router.currentRoute.query.id })
      .then( (response) => {
        console.log(response.data);
        userData.value = response.data.data ;
        console.log("userData.value", response.data.data.clientmemberid);

        store.dispatch('apps-suggestions/fetchUser', { id: response.data.data.clientmemberid })
      .then(response => {
        console.log("data from view");
        console.log(response.data.users);
        userDetailData.value = response.data.users })
      .catch(error => {
        if (error.response.status === 404) {
          userDetailData.value = undefined
        }
      })
      
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

     



    return {
      userData,
      userDetailData
    }
  },
}
</script>

<style>

</style>
